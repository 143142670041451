import * as anchor from "@project-serum/anchor";

export const programId = new anchor.web3.PublicKey(
  "cndyAnrLdpjq1Ssp1z8xxDsB8dxe7u4HL5Nxi2K5WXZ"
);

export const NETWORK = "mainnet";
// export const NETWORK = "devnet";

export const config = new anchor.web3.PublicKey(
  "F1AQWdvMgcnA5bC94LbT4JRPATarWLXBJeFUY9hd7SU4"
);
export const configUUID = "F1AQWd";

export const candyMachineOwner = new anchor.web3.PublicKey(
  "KreEPyFYxXUr628ZC6nf78ssA7WE9UhrioB1PvzCEBq"
);
