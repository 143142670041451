import React, { useState } from "react";
import "./App.css";
import { WalletProvider, useWallet } from "./providers/useWallet";
import { mintNFT } from "./actions/mintNFT";
import { CandyMachineProvider, useCandyMachine } from "./providers/useCandyMachine";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

function App() {
  return (
    <div className="App">
      <WalletProvider>
        <CandyMachineProvider>
          <BuyScreen />
        </CandyMachineProvider>
      </WalletProvider>
    </div>
  );
}

function BuyScreen() {
  const { connection, wallet, connectWallet } = useWallet();
  const { candyMachine, findCandyMachine } = useCandyMachine();
  const [ showHouse, setShowHouse ] = useState<boolean>(true);

  let displayRemaining = "";
  if (connection && wallet && !candyMachine) {
    findCandyMachine(connection, wallet);
    console.log(candyMachine);
  }
  if (candyMachine) {
    displayRemaining =
      (6666 - candyMachine.itemsRedeemed.toNumber()).toString() +
      "/6666";
  }

  const showToast = (txnId:string) => {
    toast(<a href={`https://explorer.solana.com/tx/${txnId}`}>{txnId}</a>, {
      className: "toastFont",
    });
  };
  if(showHouse){
    return (
      <img
        className="landing"
        src={"/landing.gif"}
        onClick={() => {
          setShowHouse(false);
        }}
      ></img>
    );
  }
  return (
    <header className="App-header">
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="Container">
        <h1>Kreepys</h1>
        <div className="grid2x2">
          {(!wallet || !wallet.connected) && (
            <>
              <div
                onClick={() => {
                  connectWallet("sollet");
                }}
              >
                Sollet
              </div>
              <div
                className="phantom"
                onClick={() => {
                  connectWallet("phantom");
                }}
              >
                Phantom
              </div>
            </>
          )}
          {wallet && wallet.connected && (
            <>
              <div
                onClick={() => {
                  mintNFT(connection, wallet, showToast);
                }}
              >
                Mint ◎.23<br></br>
                {displayRemaining}
              </div>
            </>
          )}
        </div>
      </div>
    </header>
  );
}
export default App;
